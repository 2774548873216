import { CarrierId } from '@graphql/generated';

export default {
  offers(quoteId: string) {
    return `/cotacao/${quoteId}`;
  },
  offersRecommended(quoteId: string) {
    return `${this.offers(quoteId)}/personalizacao`;
  },
  offersLoading(quoteId: string) {
    return `${this.offers(quoteId)}/buscando`;
  },
  leadBroker(quoteId: string) {
    return `${this.offers(quoteId)}/finalizado`;
  },
  offersRecommendedQuestion(quoteId: string, questionId: string) {
    return `${this.offersRecommended(quoteId)}?q=${questionId}`;
  },
  offerFinishPath:
    '/cotacao/[quoteId]/[offerId]/contratar/[currentQuestionIndex]',
  offer(quoteId: string, offerId: string) {
    return `${this.offers(quoteId)}/${offerId}`;
  },
  offerFinish(
    quoteId: string,
    offerId: string,
    carrier: CarrierId | null,
    currentQuestionIndex = 1,
  ) {
    if (carrier === CarrierId.Youse) {
      return this.youseFinish(quoteId, offerId);
    }

    return `${this.offer(quoteId, offerId)}/contratar/${currentQuestionIndex}`;
  },
  startForm: (questionId?: string) =>
    questionId ? `/inicio?q=${questionId}` : '/inicio',
  partnerForm: (questionId?: string) =>
    questionId ? `/parceiro?q=${questionId}` : '/parceiro',
  renewForm: (questionId?: string) =>
    questionId ? `/renovar?q=${questionId}` : '/renovar',
  suhaiFlow: (questionId?: string) =>
    questionId ? `/suhai-flow?q=${questionId}` : '/suhai-flow',
  youseFinish(quoteId: string, offerId: string) {
    return `${this.offer(quoteId, offerId)}/youse/`;
  },
};
